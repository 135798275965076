import { CompanyService } from 'src/app/services/company.service';
import { QUIZ_TYPES } from '../../resources/quiz-types';
import { QuizAnswer } from '../../models/quiz.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Quiz } from 'src/app/models/quiz.model';
import { Router, NavigationStart, Event } from '@angular/router';
import { QuizService } from 'src/app/services/quiz.service';
import { slideInAnimation } from 'src/app/router-animations';
import { LoaderService } from 'src/app/services/loader.service';
import { QuizStoreService } from 'src/app/services/quiz-store.service';
import { JobApplication } from 'src/app/models/job-application.model';
import { readQueryParameters, setQuizTypeQueryParam } from 'src/app/shared-functions';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Observable, Subject } from 'rxjs';
import { ProgressBarService } from 'src/app/services/progress-bar.service';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { Job } from 'src/app/models/job.model';
import { ComponentCanDeactivate } from '../../guards/can-deactivate-component.guard';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  animations: [slideInAnimation],
})
export class QuizComponent implements OnInit, OnDestroy, ComponentCanDeactivate {

  quiz: Quiz;
  activeQuestionIndex = 0;
  followUpAnswerIndex: number;
  jobApplication: JobApplication = this.configService.config.jobApplication;

  canDeactivate = false;

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    private quizService: QuizService,
    private quizStore: QuizStoreService,
    private configService: AppConfigService,
    private progressBarService: ProgressBarService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.trackRouterEvents();

    this.loaderService.show();

    if (readQueryParameters('job')) {
      this.getAndSetupKnockoutQuestions();
    } else if (readQueryParameters('application')) {
      this.setupQuiz();
      this.loaderService.hide();
    }

    this.trackAnsweredQuestion();
  }

  trackRouterEvents(): void {
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationStart),
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe(({ navigationTrigger }: NavigationStart) => {
        this.canDeactivate = navigationTrigger !== 'popstate';

        if (navigationTrigger === 'popstate') {
          if (this.router.url.includes('/kq')) {
            this.router.navigate(['/job-details'], { queryParamsHandling: 'merge' });
          }
        }
      });
  }

  trackAnsweredQuestion(): void {
    this.quizService.questionAnswered$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((data: { quizAnswer: QuizAnswer; response: any }) => {
        this.loaderService.hide();

        if (!data.quizAnswer) {
          return;
        }

        const quizType = this.quizStore.quiz.type;

        if (quizType === QUIZ_TYPES.KNOCKOUT_QUESTIONS) {
          this.onAnsweredKnockoutQuestion(data);
        } else if (quizType === QUIZ_TYPES.BUSINESS_QUESTIONS) {
          this.followUpAnswerIndex = data.quizAnswer.hasFollowUpQuestion ? data.quizAnswer.answer : null;
          if (this.followUpAnswerIndex || this.followUpAnswerIndex === 0) {
            this.navigateToQuizQuestion();
          } else {
            this.onAnsweredBusinessQuestion(data.response);
          }
        } else if (quizType === QUIZ_TYPES.PERSONALITY_TEST) {
          this.onAnsweredPersonalityQuestion(data.response.isFinished);
        }
      });
  }

  getAndSetupKnockoutQuestions(): void {
    const jobId = sessionStorage.getItem('jobId');
    const companyId = sessionStorage.getItem('companyId');
    const companyDomain = sessionStorage.getItem('companyDomain');
    const companyGuid = sessionStorage.getItem('companyGuid');
    const jobGuid = sessionStorage.getItem('jobGuid');

    const dataFromStorageExists = this.configService.isAllJobs
      ? !!jobId && !!companyId && !!companyDomain && !!companyGuid && !!jobGuid
      : this.configService.organization.isEnterprise
        ? (!!jobId && !!companyId && !!companyGuid && !!jobGuid)
        : !!jobId && !!companyGuid && !!jobGuid;

    const getKnockoutQuestions$ = dataFromStorageExists
      ? this.quizService.getInhouseAndKnockoutQuestions()
      : this.getKnockoutQuestionsForJob();

    getKnockoutQuestions$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((data: Quiz) => {
        this.setupKnockoutQuestionsQuiz(data);
      });
  }

  getKnockoutQuestionsForJob(): Observable<Quiz> {
    return this.companyService
      .getJob()
      .pipe(
        mergeMap((job: Job) => {
          sessionStorage.setItem('jobId', job.id.toString());
          sessionStorage.setItem('jobGuid', job.guid);
          sessionStorage.setItem('companyGuid', job.company.guid);

          if (this.configService.organization.isEnterprise) {
            sessionStorage.setItem('companyId', job.company.id.toString());
          }

          if (this.configService.organization.isAllJobs) {
            sessionStorage.setItem('companyId', job.company.id.toString());
            sessionStorage.setItem('companyDomain', job.company.companyDomain.toString());
            sessionStorage.setItem('jobCompanyLogo', job.company.companyLogo.toString());
            this.configService.setJobCompanyLogo(job.company.companyLogo);
          }

          return this.quizService.getInhouseAndKnockoutQuestions();
        })
      );
  }

  setupQuiz(): void {
    if (this.jobApplication.applicationComplete) {
      this.navigateTo('/application-complete');
      return;
    }

    const activeModuleIndex = this.jobApplication.currentQuestionsModule;
    const activeModule = this.jobApplication.orderOfQuestionsModules[activeModuleIndex];

    if (activeModule === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      this.setupBusinessQuestionsQuiz();
    } else if (activeModule === QUIZ_TYPES.PERSONALITY_TEST) {
      this.navigateTo('/quiz/personality-start');
    } else if (activeModule === QUIZ_TYPES.VIDEO_QUESTIONS) {
      this.navigateTo('/video');
    }
  }

  updateProgressBar(activeQuestionIndex: number): void {
    const progress = (100 * (activeQuestionIndex + 1)) / this.quizStore.quiz.questions.length;
    this.progressBarService.updateProgress(progress);
  }

  onAnsweredKnockoutQuestion({ quizAnswer, response }: { quizAnswer: QuizAnswer; response: boolean }): void {
    this.quizStore.updateQuestionAnswer(
      this.quizStore.activeQuestionIndex,
      quizAnswer.answer
    );

    const quiz = this.quizStore.quiz;

    if (!quizAnswer.isInhouseQuestion && !quiz.passAllCandidates && !quiz.passAllInhouseCandidates && response === false) {
      this.navigateTo('/quiz/message');
    } else {
      this.loadNextKnockoutQuestion();
    }
  }

  onAnsweredBusinessQuestion(application: JobApplication): void {
    this.configService.config.jobApplication = application;
    // business questions quiz is not complete
    if (application.passBusinessQuestions === null) {
      this.activeQuestionIndex = application.nextQuestion;
      this.navigateToQuizQuestion();
    } else if (application.passBusinessQuestions) {
      // quiz is complete and user passed the test
      this.loadNextQuestionModule();
    } else if (!application.passBusinessQuestions) {
      // quiz is complete and user failed the test
      this.navigateTo('/quiz/message');
    }
  }

  onAnsweredPersonalityQuestion(isFinished: boolean): void {
    if (!isFinished) {
      const previousQuestionIndex = this.quizStore.activeQuestionIndex;
      const nextQuestionIndex = previousQuestionIndex + 1;
      this.activeQuestionIndex = nextQuestionIndex;
      this.navigateToQuizQuestion();
    } else {
      this.loadNextQuestionModule();
    }
  }

  loadNextKnockoutQuestion(): void {
    this.quizStore.activeQuestionIndex = this.activeQuestionIndex;

    if (this.activeQuestionIndex < this.quizStore.quiz.questions.length - 1) {
      this.activeQuestionIndex = this.activeQuestionIndex + 1;
      this.navigateToQuizQuestion();
    } else {
      this.quizStore.saveKnockoutQuestions();
      this.navigateTo('/register');
    }
  }

  loadNextQuestionModule(): void {
    const activeModuleName = this.quizStore.quiz.type;
    const orderOfQuestionsModules = this.jobApplication.orderOfQuestionsModules;
    const activeModuleIndex = orderOfQuestionsModules
      .findIndex((moduleName: string) => moduleName === activeModuleName);
    this.updateProgressBar(0);

    // activeModule is the last one, complete the application
    if (activeModuleIndex >= orderOfQuestionsModules.length - 1) {
      this.navigateTo('/application-complete');
      return;
    }

    const nextModuleName = orderOfQuestionsModules[activeModuleIndex + 1];

    if (nextModuleName === QUIZ_TYPES.PERSONALITY_TEST) {
      this.navigateTo('/quiz/personality-start');
    } else if (nextModuleName === QUIZ_TYPES.BUSINESS_QUESTIONS) {
      this.loadBusinessTest();
    } else if (nextModuleName === QUIZ_TYPES.VIDEO_QUESTIONS) {
      this.activeQuestionIndex = 0;
      this.navigateTo('/video');
    }
  }

  setupKnockoutQuestionsQuiz(quiz: Quiz): void {
    if (!quiz) {
      this.loaderService.hide();
      this.navigateTo('/register');
      return;
    }

    this.quizStore.quiz = {
      ...quiz,
      type: QUIZ_TYPES.KNOCKOUT_QUESTIONS,
    };
    this.navigateToQuizQuestion();
  }

  setupBusinessQuestionsQuiz(): void {
    this.activeQuestionIndex = this.jobApplication.nextQuestion || 0;
    this.quizStore.quiz = {
      ...this.jobApplication[QUIZ_TYPES.BUSINESS_QUESTIONS],
      type: QUIZ_TYPES.BUSINESS_QUESTIONS,
    };
    const followUpAnswerIndex = readQueryParameters('following');
    this.followUpAnswerIndex = followUpAnswerIndex ? parseInt(followUpAnswerIndex) : null;

    this.navigateToQuizQuestion();
  }

  loadBusinessTest(): void {
    const jobApplication = this.jobApplication[QUIZ_TYPES.BUSINESS_QUESTIONS];

    this.quizStore.quiz = {
      type: QUIZ_TYPES.BUSINESS_QUESTIONS,
      questions: jobApplication.questions,
      rejectionMessage: jobApplication.rejectionMessage,
      rejectionMessageMain: jobApplication.rejectionMessageMain,
    };

    this.activeQuestionIndex = 0;
    this.navigateToQuizQuestion();
  }

  navigateToQuizQuestion(): void {
    this.quizStore.activeQuestionIndex = this.activeQuestionIndex;
    const quizTypeParam = setQuizTypeQueryParam(this.quizStore.quiz.type);
    this.updateProgressBar(this.activeQuestionIndex);
    this.loaderService.hide();
    this.navigateTo(`/quiz/question/${this.activeQuestionIndex}/${quizTypeParam}`);
  }

  navigateTo(route: string): void {
    this.router.navigate([route], { queryParamsHandling: 'merge', queryParams: { following: (this.followUpAnswerIndex || this.followUpAnswerIndex === 0) ? this.followUpAnswerIndex : null } });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
